import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router) {
    if (localStorage.getItem("token")) {
      console.log("true");
    } else {
      console.log("NO ");
    }
  }

  ngOnInit() {}

  logoutFunc() {
    //alert(5673);
    this.router.navigateByUrl('auth/login');
  }
}
