import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { RequestService } from "src/servcs/http-service.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder, 
    public router: Router,
    public http: RequestService) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    if (this.loginForm.value["email"] != "" && this.loginForm.value["password"] != "") {
      let user = {
        'email': this.loginForm.value["email"],
        'password': this.loginForm.value["password"],
      };
      this.http.post('admin_auth', user).then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.token);        
        this.router.navigate(["/dashboard/default"]);  
      });

     
    }  else {
      alert("Заповніть усі поля!");
    }
  }

  showPassword(){
    this.show = !this.show
  }
}
